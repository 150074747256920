import React from "react";
import { Link } from "gatsby";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Img,
  Stack,
  Text,
  SimpleGrid,
  useTheme
} from "@chakra-ui/react";
import YouTube from "react-youtube";

import Template from "src/templates/index";
import UnderConstructionGif from "src/images/under-construction.gif";
import { SetTitle } from "atoms/SetTitle";

const HelpList = ({ heading, children }) => (
  <Box mb={10}>
    <Heading mb={2}>{heading}</Heading>
    {children}
  </Box>
);

const HelpLink = props => (
  <Text textStyle={["paragraph", "link"]} mb={1} as="div">
    <Link {...props} />
  </Text>
);

const HelpPage = () => {
  return (
    <Template>
      <SetTitle title="Wrdie - Help" />
      <Container textAlign="center" minWidth="container.xl" my="5">
        <Heading textAlign="center" as="h1" mb="9" size="3xl">
          Help & Documentation
        </Heading>

        <Box align="center" mb="20px">
          <YouTube videoId="BYMGrKr-q_0" />
        </Box>

        <HelpList heading="Editor Tutorials">
          <HelpLink to="/help/getting-started">Getting Started</HelpLink>
          <HelpLink to="/help/locations-and-exits">
            Locations and Exits
          </HelpLink>
        </HelpList>
        <HelpList heading="Reference">
          <HelpLink to="/help/tag-reference">Tag Reference</HelpLink>
        </HelpList>
      </Container>
    </Template>
  );
};

export default HelpPage;
